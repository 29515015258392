import Modules from "./modules";

/**
 * Class LatestPosts
 */
class LatestPosts {
    /**
     * Class constructor
     * @param {HTMLElement} module
     */
    constructor(module) {}

    /**
     * Returns the class name
     * @return {string}
     */
    static getName() {
      return "js-latest_posts";
    }
}

new Modules(LatestPosts);